.carousel .slide {
  max-width: 100%;
}

.carousel .control-dots .dot {
  background: lightgray !important; /* color when not selected */
  box-shadow: none !important; /* remove shadow effect */
}

.carousel .control-dots .dot.selected {
  background: #2e7d32 !important;
}

/* .carousel .control-arrow,
.carousel.carousel-slider .control-arrow::before {
  opacity: 1;
} */

.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

.carousel.carousel-slider .control-arrow {
  top: 50%;
  height: 50px;
  margin-top: -25px;
}

.carousel .control-prev.control-arrow::before {
  border-right-width: 8px;
  border-right-style: solid;
  border-right-color: #2e7d32;
}
.carousel .control-next.control-arrow::before {
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: #2e7d32;
}
.carousel .control-prev.control-arrow::before,
.carousel .control-next.control-arrow::before {
  opacity: 1;
}
