@import "restaurantcreate.css";
@media only screen and (max-width: 599px) {
  /* body {
    height: 99vh;
     height: calc(100vh + 100px); 
  } */
  .text-form {
    text-align: left;
    flex-grow: 1;
    width: 75vw;
    max-width: 90%;
    font-size: 1.5em;
  }

  .result-body {
    margin-left: 0em;
  }
  .footer.open {
    bottom: -64px;
    position: absolute;
  }
  .footer.closed {
    bottom: calc(-50vh - 42px);
    position: absolute;
  }
  .flex-text {
    width: 100%;
  }
  .form-label {
    flex-grow: 1;
  }
  .form-label-inline {
    flex-grow: 1;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow: auto; */
  }
  .result-sidebody-holder {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .result-sidebody-holder.closed {
    top: calc(50vh - 42px);
  }
  .result-sidebody-holder.open {
    /* transition: top 0.5s ease-out; */
    top: 0;
  }
  .form-control {
    min-width: 200px;
    flex: 1;
  }

  .map-holder {
    /* z-index: 100; */
    width: 100vw;
    height: 50vh;
    position: fixed;
    top: 0;
  }

  .mobile-only {
    background-color: #2e7d32;
    width: 100vw;
    padding: 5px 0px;
  }
  .mobile-only.closed {
    position: fixed;
    top: 50vh;
  }

  .navbar {
    z-index: 100;
    position: relative;
  }
  .result-sidebody {
    background-color: white;
    width: 100vw;
    z-index: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0em;
  }
  /* .result-sidebody.open {
    transition: top 0.5s ease-out;
    top: 118px;
  } */
  /*
   .result-sidebody.closed {
     transition: top .5s ease-out;
     top: calc(50vh + 118px);
   } */
  .result-row {
    border-bottom: 2px solid #808080;
    margin-bottom: 1vw;
    display: flex;
    /* flex-grow: 1; */
    /* padding: 0.5em 1em; */
    align-content: space-between;
    flex-wrap: wrap;
    font-size: 1.5em;
    max-width: 100%;
  }
  .result {
    /* font-size: 1.5em; */
    width: 100%;
    padding: 0.5em 1em;
  }
  .result-header {
    padding: 0.5em 1em;
    width: revert;
    display: none;
  }
  .search-tools-container {
    width: auto;
    position: sticky;
    z-index: 100;
    top: 0;
  }
}

@media only screen and (min-width: 600px) {
  .footer {
    bottom: 0;
  }
  .main-app {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .form-control {
    flex: 0 0 0%;
  }
  .text-form {
    max-width: 50vw;
  }
  .mobile-only {
    display: none;
  }
  .result-sidebody-holder {
    position: relative;
    height: 80vh;
    max-height: 100%;
    min-height: 0;
    float: left;
    margin-left: 1em;
    overflow: auto;
    width: 30%;
    display: flex;
  }
  .map-holder {
    width: 65vw;
    display: flex;
    /* position: absolute; */
    height: 85vh;
  }
  .checkbox-grid {
    max-width: 75vw;
  }
  .result-body {
    margin-left: 1em;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    /* overflow: auto; */
  }
  /* .form-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: left;
    width: 75vw;
  } */
  .result-sidebody {
    /* display: none; */
    /* width: 0%; */
    max-width: 100%;
    box-sizing: border-box;
    padding: 1em;
  }
  .result {
    /* border-bottom: 2px solid #808080;
  margin-bottom: 2em; */
    font-size: 1.5em;
    width: 100%;
  }
  .result-header {
    padding: 0.5em 0em;
  }
  .search-tools-container {
    width: auto;
    position: absolute;
    z-index: 100;
    top: 64px;
    left: 35vw;
  }
}

.footer {
  background: #2e7d32;
  color: white;
  text-align: center;
  padding: 5px 0px;
  left: 0;
  /* position: absolute; */
  width: 100%;
}
.result-row {
  border-bottom: 2px solid #808080;
  margin-bottom: 2em;
  max-width: 100%;
}
#root {
  height: 100%;
}
.App {
  display: flex;
  width: 100%;
  max-width: 100%;
  /* overflow: hidden; */
  /* max-height: 100vh; */
  /* height: 100vh; */
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  position: relative;
}
body {
  font-family: Sans-Serif;
  margin: 0;
  /* overflow: hidden; */
}
/* .container {
  min-height: 0;
  overflow: hidden;
} */

.result-body {
  color: #808080;
  font-size: 0.8em;
  margin-bottom: 1em;
  box-sizing: border-box;
  max-width: 100%;
  flex: 1 1 0;
}

.result-option {
  line-height: 1.2em; /* Adjust as needed */
  /* height: calc(3.6em + 20px); line-height * number of lines */
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
}

.admin-option {
  justify-content: flex-end;
  display: flex;
  text-align: right;
  font-size: 0.6em;
}

.selected {
  /* height: 50%; */
  z-index: 100;
  font-weight: bold;
}

.navbar-brand img {
  height: 50px;
}

.navbar {
  z-index: 100;
  min-height: 2em;
  background-image: linear-gradient(to right, #80aa82, #2e7d32);
  /* background-color: #2e7d32; */
  margin: 0;
  display: flex;
  justify-content: "flex-start";
}
.btn-primary,
.btn-primary:active:focus,
.btn-primary:active,
.btn-primary.active .btn-primary:focus {
  background-color: #2e7d32;
  color: white;
  height: 36px;
  border-radius: 5px;
  font-size: 1em;
  padding: 0.3em;
}

.btn-primary:hover {
  /* background-color: rgb(4, 206, 4); */
  color: #80aa82;
}
.nav-btn,
.nav-btn:active,
.nav-btn:visited,
.nav-btn:visited,
.nav-btn {
  /* margin: 1em 0em 1em 1em; */
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px 5px;
  text-decoration-color: white;
}

.nav-btn:hover {
  color: #80aa82;
}

.checkbox-grid {
  /* height: 5em; */
  max-height: 200px;
  overflow: auto;
}

.checkbox-grid ul {
  padding: 0px;
}
.checkbox-grid li {
  text-align: left;
  list-style-type: none;
  /* display: block-inline; */
  min-width: 25%;
}
.closeButton {
  color: #aaa;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 28px;
  font-weight: bold;
}

.closeButton:hover,
.closeButton:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.disabled {
  position: absolute;
  right: 0;
  background-color: darkgray;
  opacity: 0.5;
}

.none {
  display: none;
  /* visibility: hidden; */
  height: 0px;
}

.form-option {
  background: #2e7d32;
  height: 2em;
  /* width: 30vw; */
  left: 0;
  right: 0;
  top: 32px;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.form-option:hover {
  background-color: rgb(4, 206, 4);
}

.form-control {
  display: flex;
  height: 30px;
  text-align: center;
}
.form-label {
  display: block;
  font-size: 1.5em;
  color: #808080;
}

.form-label-inline {
  font-size: 1.5em;
  color: #808080;
}
.text-form {
  text-align: left;
  border: 1px solid darkgray;
  border-radius: 3px;
  flex-grow: 1;
}

input {
  text-align: center;
}
input[type="text"] {
  font-size: 16px;
  min-width: 150px;
}
.vertical-center:hover {
  text-decoration: underline;
}
.alert-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 60%;
  display: flex;
}

.container1 {
  position: relative;
  z-index: 101;
}

.success {
  background-color: green;
  opacity: 0.5;
}

.failure {
  background-color: red;
  opacity: 0.5;
}

.inline-search-button {
  color: white;
  background-color: #2e7d32;
  font-family: "FontAwesome";
  position: absolute;
  right: 0px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid white;
  padding-right: 0;
  appearance: "button";
}

.map-holder {
  /* z-index: -1; */
  right: 0;
}

.map-marker-tag {
  white-space: nowrap;
  width: min-content;
  position: relative;
  top: -64px;
  left: 16px;
  background: white;
  background-size: auto;
  border-radius: 10px;
  padding: 0px 5px;
  z-index: 100;
  box-shadow: 2px 2px grey;
}

.flex-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.noFlex {
  display: block;
}
.result-header {
  border-bottom: 2px solid #808080;
  font-size: 1.5em;
  margin-bottom: 2em;
}

.search-tools-style {
  z-index: 100;
  background-color: #2e7d32;
  color: white;
  padding: 4px;
  border: 1px solid white;
  border-radius: 10px;
}

.absolute {
  position: absolute;
}
